
//学生登录
export function classLogin(gradeNum) {
  return request.post("class/login", { gradeNum });
}

//pin码登录
export function pinLogin(studentId, pin) {
  return request.post("student/login", { studentId, pin });
}

//获取学生班级学生列表
export function getClassStudentList(gradeId) {
  return request.get("students", { params: { gradeId } });
}

//学生登录
export function studentLoginPassword(mobile, password) {
  return request.post("student/login/password", { mobile, password });
}

//老师登录
export function teacherLogin(name, pwd) {
  return request.post("teacher/login", { name, pwd });
}

//手机号查重
export function verifyPhone(mobile) {
  return request.post("student/mobile/find-repeat", { mobile })
}

//发送短信
export function sendCode(mobile, uuid) {
  return request.post("sms/code", { mobile, uuid })
}

//短信登录
export function loginSms(mobile, verifyCode) {
  return request.post("student/login/sms", { mobile, verifyCode })
}

//手机绑定
export function bindPhone(mobile, sex, verifyCode, password) {
  return request.post("student/mobile/bind", { mobile, sex, verifyCode, password })
}