import store from "@/store";
import MobileDetect from "mobile-detect";
export default {
    created() {
        const md = new MobileDetect(window.navigator.userAgent);
        const os = md.os()
        if (os == "iOS" || os == "AndroidOS") {
            window.location.href =
                "https://a.app.qq.com/o/simple.jsp?pkgname=com.hongyear.readbook";
        }

        if (!document.cookie || document.cookie.indexOf("open_time") === -1) {
            document.cookie =
                "open_time=" + this.$moment().format("YYYY-MM-DD HH:mm:ss");
            this.$track.event({
                category: "launch",
                name: "launch",
                needJwt: store.state.auth.jwt ? true : false,
            });
        }
    },
};
