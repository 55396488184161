<template>
  <v-row class="px-10" justify="center">
    <v-btn
      class="btn"
      :style="{ width: width, height: height, marginTop: marginTop }"
      :color="color"
      :ripple="{ class: 'pressed--text' }"
      depressed
      rounded
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot></slot>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "submit-btn",
  inheritAttrs: false,
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "44px",
    },
    marginTop: {
      type: String,
      default: "0px",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style lang='scss' scoped>
.btn {
  font-size: 16px;
  font-weight: 400;
}
</style>