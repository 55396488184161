<template>
  <div class="pc-login-container">
    <v-row
      class="pa-0"
      :style="{
        marginTop: topTitleMarginTop,
        marginLeft: topTitleMarginLeft,
      }"
    >
      <div class="pc-login-text-top" @click="clickFirstTitle">登录</div>

      <v-icon class="pc-login-top" color="#444444" size="14"
        >icon-right-arrow</v-icon
      >
      <div class="pc-login-top pc-login-text-top" @click="clickSecondTitle">
        {{ topSecondTitle }}
      </div>

      <v-icon
        v-show="showThirdTitle"
        class="pc-login-top"
        color="#444444"
        size="14"
        >icon-right-arrow</v-icon
      >
      <div
        v-show="showThirdTitle"
        class="pc-login-top pc-login-text-top"
        @click="clickThirdTitle"
      >
        {{ topThirdTitle }}
      </div>
    </v-row>
    <v-col class="pa-0 ma-0">
      <v-row
        class="pa-0 mx-0"
        :style="{
          marginTop: centerTitleMarginTop,
          marginBottom: centerTitleMarginBottom,
        }"
        justify="center"
      >
        <div class="pc-login-text-center">{{ centerTitle }}</div>
      </v-row>

      <slot></slot>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "pc-login-container",
  props: {
    topSecondTitle: {
      //上方第二个标题
      type: String,
      default: "",
    },
    topThirdTitle: {
      //上方第三个标题
      type: String,
      default: "",
    },
    showThirdTitle: {
      //是否显示上方第三个标题
      type: Boolean,
      default: false,
    },
    centerTitle: {
      //中间标题
      type: String,
      default: "",
    },
  },
  data() {
    return {
      screenWidth: 0, //屏幕宽度
      screenHeight: 0, //屏幕高度
      topTitleMarginTop: "60px", //顶部标题上外边距
      topTitleMarginLeft: "360px", //顶部标题左外边距
      centerTitleMarginTop: "194px", //中间标题上外边距
      centerTitleMarginBottom: "40px", //中间标题下外边距
    };
  },
  components: {},
  computed: {},
  methods: {
    //点击第一个节点
    clickFirstTitle() {
      this.$emit("clickFirstTitle");
    },
    //点击第二个节点
    clickSecondTitle() {
      this.$emit("clickSecondTitle");
    },
    //点击第三个节点
    clickThirdTitle() {
      this.$emit("clickThirdTitle");
    },
    //返回像素值
    getSize(size) {
      return Math.round(size) + "px";
    },
  },
  watch: {},
  created() {
    this.screenWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    this.screenHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    let x = this.screenWidth / 1920;
    let y = this.screenHeight / 1080;
    this.topTitleMarginLeft = this.getSize(360 * x);
    this.topTitleMarginTop = this.getSize(60 * y);
    this.centerTitleMarginTop = this.getSize(194 * y);
    this.centerTitleMarginBottom = this.getSize(40 * y);
  },
};
</script>

<style lang='scss' scoped>
.pc-login-container {
  width: 100%;
  height: 100%;
  background: url("~@/assets/bg_pc_login.png") no-repeat center;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  .pc-login-text-top {
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
  .pc-login-top {
    margin-left: 14px;
  }
  .pc-login-text-center {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
}
</style>